$app-bg: #FFFFFF;
$wrapper-bg: #2f2f2f;
$white: #FFFFFF;
$dark: #030303;
$text-color: #ebebeb;
$border-color: #959595;
$link-color: #030303;
$hover-link-color: #818181;
$button-bg: #171717;
$hover-button-bg: #030303;

a {
  color: $link-color;
}
a:hover {
  color: $hover-link-color;
}

.app {
  background: $app-bg;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wrapper {
  padding: 0;
}

.inner {
  max-width: 600px;
  padding: 0;
  margin: 51px auto 1px;
  background: $wrapper-bg;
  border: 1px solid $border-color;
  border-radius: 5px;
}

.subscription-container{
  max-width: 800px;
  padding: 1px;
  margin: 51px auto 1px;
  background: none;
}

.wrapper-logo{
  background: #F0BA38;
  position: relative;
  height: 81px;
  &:after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 3px;
    width: 100%;
    height: 3px;
    background: #FFFFFF;
  }
}

.logo {
  width: 260px;
  height: 160px;
  background: url("//webcdn.triongames.com/images/common/gamigogroup_logo.png") 100% center no-repeat;
  background-size: 100%;
  position: absolute;
  left: 50%;
  bottom: -63px;
  transform: translate(-50%, 0);
  z-index: 1;
}

.channel-name {
  color: $dark;
}

.item {
  padding: 10px 0;
  color: $dark;
  display: flex;
}

.item-checkbox {
  margin: 0 10px 0 10px;
}

label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.save-button {
  color: $text-color;
  border: 1px solid $border-color;
  background: $button-bg;
  border-radius: 0;
  padding: 12px 44px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
}

.save-button:hover {
  background: $hover-button-bg;
}
.save-button:disabled {
  background: $border-color;
  cursor: not-allowed;
  &:hover {
    background: $border-color;
  }
}

.list-controls {
  padding: 20px 0;
  display: flex;
  justify-content: center;
}

.control {
  border: 0;
  background: none;
  color: $link-color;
  font-weight: 800;
  text-decoration: underline;
  cursor: pointer;
}

.legal-copyright-wrapper{
  padding: 1px 3%;
}

.control:hover {
  color: $hover-link-color;
}

.loading-wrapper {
  height: 100px;
  display: flex;
  justify-content: center;
}

.error-message {
  text-align: center;
  color: $dark;
  font-size: 2em;
  font-weight: 700;
}

.success-message {
  h1 {
    text-align: center;
    color: $dark;
    font-size: 2em;
    font-weight: 700;
  }
  .success-text {
    text-align: center;
    color: $dark;
  }
}

.title {
  color: $dark;
  text-align: center;
  padding: 31px 9px 1px;
  font-size: 18px;
  span{
    font-size: 29px;
    font-weight: 600;
  }
}

.description {
  color: $dark;
  padding: 1px;
  font-size: 19px;
  font-weight: 500
}

.border-description{
  margin: 31px 1px;
  height: 3px;
  width: 100%;
  border-radius: 43%;
  background: $dark;
}

.list-container{
  padding: 1px 51px;
}

.information{
  max-width: 430px;
  margin: 31px auto;
  border: 3px solid $dark;
  padding: 19px;
}

footer {
  //border-top: 2px solid $border-color;
  background: #F0BA38;
  padding-bottom: 31px;
  color: $text-color;
}

.footer-inner {
  //max-width: 600px;
  margin: auto;
  text-align: center;
}

.legal-links-wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  a {
    margin-left: 10px;
  }
}

.legal-links-block{
  padding: 16px 0;
  border-bottom: 3px solid white;
  margin-bottom: 13px;

  a{
    color: white;
    text-decoration: none;
    border-right: 1px solid white;
    padding-right: 16px;
    &:last-child{
      border-right: none;
    }
  }
}

.footer-logo {
  background: url("//webcdn.triongames.com/images/common/gamigogroup_logo.png");
  width: 177px;
  height: 84px;
  margin: auto;
  display: none;
}
